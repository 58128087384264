import React from "react";
import "../styles/Sellers.css";
import sellers_img from "../imgs/sellers_img.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  imgWrapper: {
    maxWidth: "100%",
  },
}));

function Sellers() {
  const classes = useStyles();
  return (
    <>
      <div>
        <img className={classes.imgWrapper} src={sellers_img}></img>
      </div>
    </>
  );
}

export default Sellers;
