import React from "react";
import "../styles/Articles.css";
import integrationImg from "../imgs/integration_img.png";
import { Button, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  integrationWrapper: {
    display: "flex",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "800px",
      flexDirection: "row",
    },
    background:
      "radial-gradient(100% 576% at 100% 0%, #93c2ff 0%, #368dff 100%);",
  },
  integrationTextWrapper: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("lg")]: {
      marginLeft: "120px",
      marginRight: "120px",
      marginTop: "60px",
      marginBottom: "60px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "auto",
    },
  },
  integrationTextBox: {
    [theme.breakpoints.down("lg")]: {
      margin: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "auto",
      marginRight: "3.5vw",
    },
  },
  integrationTextHeader: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "column",
    },
  },
  integrationTextBody: { marginTop: "24px" },
  integrationButtonBox: {
    marginTop: "40px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#ffffff",
  },
  integrationButton: { margin: "auto", padding: "1.6vh 4.16vw" },
  integrationImageWrapper: { height: "100%", display: "flex" },
  integrationImage: {
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "64.25vw",
    },
  },
}));

function Integration() {
  const classes = useStyles();
  return (
    <div className={classes.integrationWrapper}>
      <div className={classes.integrationImageWrapper}>
        <img className={classes.integrationImage} src={integrationImg}></img>
      </div>
      <div className={classes.integrationTextWrapper}>
        <div className={classes.integrationTextBox}>
          <div className={classes.integrationTextHeader}>
            <Typography variant="h1">Quick &amp;&nbsp;</Typography>
            <Typography variant="h1" noWrap>
              Easy integration
            </Typography>
          </div>
          <div className={classes.integrationTextBody}>
            <Typography variant="body1">
              Drive sticker revenue and engagement throughout the year with a
              continuous flow of new stickers and optimized curations
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Integration;
