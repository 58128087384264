import React, { useEffect, useState } from "react";
import { Link, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  articleItemWrapper: {
    width: "364px",
    height: "410px",
    marginLeft: "18px",
    marginRight: "18px",
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
  },
  articleLogoWrapper: {
    display: "flex",
  },
  articleItemImg: { width: "inherit", height: "200px" },
  articleItemLogo: {
    height: "27px",
    marginLeft: "24px",
    marginRight: "24px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  articleTitleWrapper: {
    marginLeft: "24px",
    marginRight: "24px",
    marginTop: "24px",
    marginBottom: "20px",
  },
  articleItemTitle: {
    fontFamily: "Gmarket Sans TTF",
    fontWeight: "500",
    fontStyle: "normal",
    fontSize: "18.5px",
    lineHeight: "27.75px",
  },
  articleItemDateWrapper: {
    display: "flex",
    marginTop: "12px",
    height: "40px",
  },
  articleItemDateImg: {},
  articleItemDateTitle: {},
  articleItemReadMore: {
    marginLeft: "24px",
    marginRight: "24px",
    marginTop: "auto",
    marginBottom: "22px",
  },
  articleDate: { marginTop: "auto", marginBottom: "auto" },
  articleTypographyBody3: {
    fontFamily: "Gmarket Sans TTF",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "28.8px",
    color: "#3278FF",
  },
}));

function ArticleItem({
  imgUrl,
  linkUrl,
  articleLogo,
  articleDate,
  articleTitle,
}) {
  const classes = useStyles();
  const [ogMetaData, setOgMetaData] = useState([]);

  useEffect(() => {});

  return (
    <div className={classes.articleItemWrapper}>
      <div>
        <img className={classes.articleItemImg} src={imgUrl}></img>
      </div>
      <div className={classes.articleItemDateWrapper}>
        <div className={classes.articleLogoWrapper}>
          <img className={classes.articleItemLogo} src={articleLogo}></img>
        </div>
        <div className={classes.articleDate}>
          <Typography variant="caption">{articleDate}</Typography>
        </div>
      </div>
      <div className={classes.articleTitleWrapper}>
        <Typography className={classes.articleItemTitle}>
          {articleTitle}
        </Typography>
      </div>
      <div className={classes.articleItemReadMore}>
        <Link underline="none" href={linkUrl}>
          <Typography className={classes.articleTypographyBody3}>
            READ MORE &#62;
          </Typography>
        </Link>
      </div>
    </div>
  );
}
export default ArticleItem;
