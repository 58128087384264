import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
//import Alert from '@material-ui/core/lab/Alert';
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import "../styles/Emailcontact.css";
import emailcontactImg from "../imgs/emailcontact_img.png";
import { Modal, Typography } from "@material-ui/core";
import ContactSuccessPage from "./ContactSucessPage";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  emailContactWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  emailContactTextWrapper: { marginLeft: "12.5vw", marginTop: "160px" },
  emailContactHead: {},
  emailContactBody: { marginTop: "32px" },
  emailContactContent: { display: "flex" },
  emailContactImg: { width: "100%", height: "auto" },
  emailContactImgWrapper: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    display: "block",
    marginTop: "120px",
    marginLeft: "7.91vw",
    width: "40vw",
  },
  emailContactFormWrapper: {
    marginLeft: "12.5vw",
    marginRight: "12.5vw",
    display: "flex",
    flexGrow: "1",
    flexFlow: "column",
  },
  emailContactForm: {
    marginTop: "76px",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#919BA9",
  },
  emailContactButtonBox: {
    marginTop: "137px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#3278FF",
  },
  emailContactButton: {
    margin: "auto",
    color: "#ffffff",
    padding: "1.6vh 4.16vw",
  },
}));

function EmailContact(prop) {
  const [companyName, setCompanyName] = useState("");
  const [appName, setAppName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSendError, setIsSendError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendMail, setIsSendMail] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const onHandleContact = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // const url = process.env.REACT_APP_CONTACT_API_URL;
    const url = process.env.REACT_APP_SHEET_ADD_API_URL;

    console.log(url);

    const values = {
      user: {
        companyName,
        appName,
        email,
        message,
      },
    };

    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        setIsSendMail(true);
        handleOpen();
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.log(error);
        setIsSendError(true);
      });
  };

  return (
    <>
      <div id="emailContact" className={classes.emailContactWrapper}>
        <div className={classes.emailContactTextWrapper}>
          <div className={classes.emailContactHead}>
            <Typography className={classes.emailContactHead} variant="h1">
              Let's talk about your business
            </Typography>
            <Typography className={classes.emailContactBody} variant="body1">
              We’d love to hear how we can support your app.
            </Typography>
          </div>
        </div>
        <div className={classes.emailContactContent}>
          <div className={classes.emailContactImgWrapper}>
            <img
              className={classes.emailContactImg}
              src={emailcontactImg}
            ></img>
          </div>
          <div className={classes.emailContactFormWrapper}>
            <ValidatorForm
              onSubmit={(e) => onHandleContact(e)}
              onError={(errors) => console.log(errors)}
            >
              <TextField
                className={classes.emailContactForm}
                margin="normal"
                placeholder="Name*"
                onChange={(e) => setAppName(e.target.value)}
                value={appName}
              />
              <TextValidator
                className={classes.emailContactForm}
                margin="normal"
                onChange={(e) => setCompanyName(e.target.value)}
                validators={["required"]}
                id="standard-required"
                placeholder="Company Name*"
                errorMessages={["Please let us know your name."]}
                value={companyName}
              />

              <TextValidator
                className={classes.emailContactForm}
                margin="normal"
                onChange={(e) => setEmail(e.target.value)}
                validators={["required", "isEmail"]}
                placeholder="Email address *"
                errorStyle="input"
                errorMessages={[
                  "Please let us know your email address.",
                  "Email is not valid",
                ]}
                value={email}
              />
              <TextField
                className={classes.emailContactForm}
                margin="normal"
                onChange={(e) => setMessage(e.target.value)}
                placeholder={"Tell us how we can support you :-)"}
                value={message}
              />
              {/* <input type="submit" value="Submit"/> */}
              <Button
                id="GET_IN_TOUCH"
                type="submit"
                className={classes.emailContactButtonBox}
              >
                <Typography
                  className={classes.emailContactButton}
                  variant="button1"
                >
                  SEND A MESSAGE
                </Typography>
              </Button>
            </ValidatorForm>
          </div>
        </div>

        <Modal open={open} onClose={handleClose}>
          <ContactSuccessPage />
        </Modal>
      </div>
    </>
  );
}

export default EmailContact;
