import React, { useState } from "react";
import "../styles/Stickers.css";
import stickers_img from "../imgs/stickers_img.png";
import stickers_vid from "../imgs/mock_up_sticker_01.mp4";
import { Button, Link, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  stickerWrapper: {
    position: "relative",
    width: "100%",
    minHeight: "300px",
    // #0067ed 0%,
    // #00275a 81.72%'
    background:
      "radial-gradient(339.64% 342.93% at 100% 0%,#0067ed 0%,#00275a 81.72%)",
    display: "flex",
  },
  stickerTextWrapper: {
    position: "absolute",
    top: "50%",
    transform: "transLateY(-50%)",
    left: "12.5vw",
    marginTop: "auto",
    marginBottom: "auto",
    height: "inherit",
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
  },
  stickerVideoWrapper: {},
  stickerTextHeader: { color: "#ffffff" },
  stickerTextBox: { margin: "auto" },
  stickerTextBodyWrapper: { marginTop: "32px" },
  stickerTextBody: { color: "#ffffff" },
  stickerTextBodyHighLight: { color: "#ffffff" },
  stickerButtonBox: {
    marginTop: "64px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#ffffff",
    border: "1px solid #D3D7DD",
  },
  stickerButtonLink: {},
  stickerButton: {
    /*Mojitok Blue 200*/
    color: "#3278FF",
    margin: "auto",
    padding: "1.6vh 4.16vw",
  },
  stickerVideo: {
    width: "inherit",
    height: "100%",
  },
}));

function Stickers() {
  const classes = useStyles();
  return (
    <>
      <div id="demo" className={classes.stickerWrapper}>
        <video
          className={classes.stickerVideo}
          loop
          src={stickers_vid}
          //poster={stickers_img}q
          autoPlay="autoplay"
          muted
        ></video>
        <div className={classes.stickerTextWrapper}>
          <div className={classes.stickerTextBox}>
            <Typography className={classes.stickerTextHeader} variant="h1">
              Try it now
            </Typography>
            <div className={classes.stickerTextBodyWrapper}>
              <Typography variant="body2" className={classes.stickerTextBody}>
                Test all the functions of mojitok
              </Typography>
              <Typography variant="body2" className={classes.stickerTextBody}>
                Sticker Store
              </Typography>
            </div>

            <Link
              className={classes.stickerButtonLink}
              underline="none"
              href="https://demo.mojitok.com/"
            >
              <Button id="GO_TO_DEMO" className={classes.stickerButtonBox}>
                <Typography className={classes.stickerButton} variant="button">
                  GO TO DEMO
                </Typography>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Stickers;
