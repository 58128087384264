import React from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "../styles/SwiperCustom.css";

import "../styles/Content.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  imgWrapper: {
    maxWidth: "100%",
  },
}));

SwiperCore.use([Autoplay]);

const useCaseTitleName = [
  "MESSAGING",
  "PHOTO EDITING",
  "VIDEO EDITING",
  "VIDEO CHAT",
  "LIVE STREAMING",
];

export default function UseCase() {
  return (
    <div className="usecase-wrapper" id="useCase">
      <h2 className="usecase-title">Use Cases</h2>
      <Swiper
        spaceBetween={0}
        slidesPerView={4.4}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        slideToClickedSlide={true}
        loop={true}
        centeredSlides={true}
      >
        {useCaseTitleName.map((text, idx) => (
          <SwiperSlide key={idx}>
            {({ isActive }) => (
              <>
                <img
                  src={require(`../imgs/usecase_0${idx + 1}.png`).default}
                  alt="section"
                  className="usecase-img"
                  effect="blur"
                  key={idx}
                />

                {isActive && <p className="usecase-text">{text}</p>}
              </>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
