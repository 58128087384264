import React from "react";
import "../styles/Support.css";
import support_img from "../imgs/support_img.png";
import { Link, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  supportWrapper: {
    background: "#3278ff",
    height: "567px",
    display: "flex",
  },
  supportContentWrapper: { margin: "auto" },
  supportTextHead: { textAlign: "center", color: "#ffffff" },
  supportTextBody: { textAlign: "center", marginTop: "32px", color: "#ffffff" },
  supportImg: { marginTop: "81px" },
}));

function Support() {
  const classes = useStyles();
  return (
    <div className={classes.supportWrapper}>
      <div className={classes.supportContentWrapper}>
        <Typography className={classes.supportTextHead} variant="h1">
          Compatible with
        </Typography>
        <Typography className={classes.supportTextBody} variant="body1">
          Android, iOS, JavaScript
        </Typography>
        <div className={classes.supportImg}>
          <Link
            className={classes.storeButtonLink}
            underline="none"
            href="https://www.notion.so/mojitok-Sticker-Store-SDK-Docs-27c5b29bb0d546549bd73e860044765d"
          >
            <img src={support_img}></img>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Support;
