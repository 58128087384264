import React from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
  imgWrapperHalfLeft: {
    width: "6.25vw",
  },
  imgWrapperHalfRight: {
    width: "6.25vw",
  },
  imgWrapper: {
    marginLeft: "16px",
    marginRight: "16px",
    width: "240px",
    height: "140px",
  },
  partnershipWrapper: {
    backgroundColor: "#ffffff",
    position: "relative",
  },
  PartnershipList: {
    width: "100%",
    marginTop: "80px",
    display: "flex",
    overflow: "auto",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  articleLeftArrow: {
    position: "absolute",
    left: 120,
    background: "#FFFFFF",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
    top: "30%",
  },
  articleRightArrow: {
    position: "absolute",
    top: "30%",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
    right: 120,
    background: "#FFFFFF",
  },
}));

const n = 11;

const sideScroll = (element, speed, distance, step) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

function Partnership() {
  const classes = useStyles();
  const contentWrapper = React.useRef(null);
  return (
    <div className={classes.partnershipWrapper}>
      <div className={classes.PartnershipList} ref={contentWrapper}>
        {[...Array(n)].map((elementInArray, index) => (
          <img
            className={classes.imgWrapper}
            src={require(`../imgs/partner_${index}.png`).default}
            key={index}
          />
        ))}
        <IconButton
          className={classes.articleLeftArrow}
          color="primary"
          onClick={() => {
            sideScroll(contentWrapper.current, 40, 200, -40);
          }}
        >
          <ChevronLeftIcon fontSize="large" />
        </IconButton>
        <IconButton
          className={classes.articleRightArrow}
          color="primary"
          onClick={() => {
            sideScroll(contentWrapper.current, 40, 200, 40);
          }}
        >
          <ChevronRightIcon fontSize="large" />
        </IconButton>
      </div>
    </div>
  );
}

export default Partnership;
