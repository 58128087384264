import React, { useEffect, useState } from "react";
import "../styles/Partnership.css";
import "../styles/UseageConter.css";
import { makeStyles, Typography } from "@material-ui/core";

import snackBarWhite from "../imgs/snack_bar_white.png";
import snackBarBlack from "../imgs/snack_bar_black.png";

const useStyles = makeStyles((theme) => ({
  mjtSnackBarWrapper: {},
  mjtSnackBarIcon: {},
  mjtSnackBarLogo: {},
  mjtSnackBarTextWrapper: {},
  mjtSnackBarText: {},
  mjtSnackBarArrow: {},
  mjtSnackBarEntireImage: {
    height: "70px",
    width: "700px",

    position: "fixed",
    marginTop: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 9999,

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
}));

function MojitokSnackBar() {
  const classes = useStyles();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollState, setScrollState] = useState("");
  const handleScroll = () => {
    const position = window.pageYOffset;
    /*s
      scroll Height = 스크롤 시키지 않았을때의 전체 높이 
      client Height = 눈에 보이는 만큼의 높이 
      window.pageYOffset = 현재 화면의 스크롤 정보 (px)
    */

    if (
      document.documentElement.scrollHeight ===
      document.documentElement.clientHeight + position
    ) {
      setScrollState("none");
    } else {
      setScrollState("block");
    }
    if (position === window.pageY) setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className={classes.mjtSnackBarWrapper}
      style={{ display: `${scrollState}` }}
    >
      <img className={classes.mjtSnackBarEntireImage} src={snackBarWhite}></img>
    </div>
  );
}

export default MojitokSnackBar;
