import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  hpWrapper: {
    maxWidth: "100%",
    height: "600px",
    display: "flex",
  },
  hpTextWrapper: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "120px",
    marginRight: "120px",
  },
  hpHead: {
    [theme.breakpoints.down("lg")]: { maxWidth: "700px" },
    [theme.breakpoints.up("lg")]: {
      minWidth: "1000px",
    },
  },
  hpBody: {
    [theme.breakpoints.down("lg")]: { marginTop: "60px" },
    [theme.breakpoints.up("lg")]: {
      marginTop: "130px",
    },
  },
}));

function HightPerformance() {
  const classes = useStyles();
  return (
    <div id="happier" className={classes.hpWrapper}>
      <div className={classes.hpTextWrapper}>
        <Typography className={classes.hpHead} variant="h2">
          Happier Users, More Revenue
        </Typography>
        <div className={classes.hpBody}>
          <Typography variant="body1">
            With mojitok, access to a library of universal stickers that improve
            the user
          </Typography>
          <Typography variant="body1">
            experience and generate app revenue is achievable in a matter of
            days
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default HightPerformance;
