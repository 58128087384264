import React, { useState } from "react";
import "../styles/Title.css";
import homeBackground from "../imgs/home_background.png";
import backgroundImage from "../imgs/title_page3.png";
import title_phone from "../imgs/home_mp4_image.png";
import message from "../imgs/home_message_icon.png";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { HashLink } from "react-router-hash-link";
import backgroundTest from "../imgs/background_test.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${backgroundTest})`,
    colorAdjust: "exact",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#3da7ff",
  },
  imgWrapper: {
    width: "100%",
  },
  videoWrapper: {
    display: "flex",
  },
  contentWrapper: {
    display: "flex",
    height: "100%",
    position: "relative",
  },
  homeTextWrapper: {
    // marginTop: "44.08vh",
    display: "flex",
    flexDirection: "column",
    marginLeft: "8.5vw",
    zIndex: 2,
  },
  homeTitle: {
    color: "#FFFFFF",
    textShadow: "0px 2px 3px #80979797, 0 0 0.2px #3da7ff",
  },
  homeTitleYellow: {
    color: "#ffdc23",
    textShadow: "0px 2px 3px #80979797, 0 0 0.2px #3da7ff",
  },
  homeDescription: {
    color: "#FFFFFF",
    textShadow: "0px 2px 3px #80979797, 0 0 0.2px #3da7ff",
  },
  homeDescriptionYellow: {
    color: "#FDFF8F",
    textShadow: "0px 2px 3px #80979797, 0 0 0.2px #3da7ff",
  },
  homeButtonBox: {
    marginTop: "64px",
    marginLeft: "30px",
    marginRight: "auto",
    marginBottom: "250px",
    backgroundColor: "#ffffff",
    border: "1px solid #D3D7DD",
  },
  homeButton: {
    margin: "auto",
    padding: "1.6vh 4.16vw",
  },
  homeVideoWrapper: {
    position: "absolute",
    right: 0,
    bottom: 0,
    height: "auto",
    width: "800px",
    [theme.breakpoints.down("sm")]: {
      opacity: "0.65",
    },
    [theme.breakpoints.up("sm")]: {
      opacity: "1",
    },
  },
  homeVideo: {
    marginTop: "auto",
    marginLeft: "auto",
    objectFit: "fill",
    height: "830px",
    width: "100%",
  },
  hashLink: {
    textDecoration: "none",
  },
  homeDescriptionWrapper: {
    marginTop: "40px",
    marginBottom: "20px",
  },
  homeMessageIcon: {
    marginTop: "20.08vh",
    width: "120px",
    height: "110px",
    marginLeft: "494px",
  },
  blurImage: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  blurBox: {
    // height: "auto",
    // backgroundColor: "#3FA8FFCC",
    // padding: "30px 37px 30px 37px",
  },
}));

function Home() {
  const classes = useStyles();

  return (
    <div id="home" className={classes.root}>
      <div className={classes.contentWrapper}>
        <div className={classes.homeTextWrapper}>
          <img
            className={classes.homeMessageIcon}
            src={message}
            alt="message icon"
          ></img>
          <div className={classes.blurBox}>
            <div style={{ display: "flex" }}>
              <Typography className={classes.homeTitle} variant="h1" noWrap>
                Ready-made
              </Typography>
              <Typography
                className={classes.homeTitleYellow}
                variant="h1"
                noWrap
              >
                &ensp;Sticker Store
              </Typography>
            </div>

            <div className={classes.homeDescriptionWrapper}>
              <div style={{ display: "flex" }}>
                <Typography
                  className={classes.homeDescriptionYellow}
                  variant="body1"
                >
                  mojitok
                </Typography>
                <Typography className={classes.homeDescription} variant="body1">
                  &nbsp;is the smartest way to launch a sticker
                </Typography>
              </div>
              <Typography className={classes.homeDescription} variant="body1">
                store. Skip past layers of development and
              </Typography>
              <Typography className={classes.homeDescription} variant="body1">
                start earning sticker revenue immediately.
              </Typography>
            </div>
          </div>
          <div>
            <HashLink className={classes.hashLink} to="#emailContact">
              <Button className={classes.homeButtonBox}>
                <Typography className={classes.homeButton} variant="button">
                  GET IN TOUCH
                </Typography>
              </Button>
            </HashLink>
          </div>
        </div>

        {/* <div className={classes.homeVideoWrapper}>
          <img
            src={title_phone}
            //poster={title_phones}
            className={classes.homeVideo}
          ></img>
        </div> */}
      </div>
    </div>
  );
}

export default Home;
