import React from "react";
import "../styles/Footer.css";
import footer_img from "../imgs/footer_logo.png";
import footer_policy from "../imgs/footer_policy.png";
import { Button, Link, makeStyles, Typography } from "@material-ui/core";
import { HashLink } from "react-router-hash-link";

import faceBookIcon from "../imgs/facebook_icon.png";
import instagramIcon from "../imgs/instagram_icon.png";
import linkedInIcon from "../imgs/linkedIn_icon.png";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footerBottomWrapper: {
    height: "80px",
    display: "flex",
    flexDirection: "row",
    marginLeft: "12.5vw",
    marginRight: "12.5vw",
    justifyContent: "space-around",
  },
  footerBottomMenuWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "auto",
    marginTop: "auto",
  },
  footerBottomMenu: {
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: "60px",
  },
  footerBottomIconWrapper: {
    marginLeft: "auto",
    marginBottom: "auto",
    marginTop: "auto",
  },
  footerBottomIcon: {
    marginLeft: "30px",
    width: "30px",
    height: "30px",
  },
  hashLink: {
    textDecoration: "none",
  },
  footerBottomIconBox: {
    minWidth: "160px",
  },
}));

function FooterBottom() {
  const classes = useStyles();
  let history = useHistory();

  const onClickReport = () => {
    console.log("clicked");
    history.push(`/privacy`);
  };
  return (
    <div className={classes.footerBottomWrapper}>
      <div className={classes.footerBottomMenuWrapper}>
        <div className={classes.footerBottomMenu}>
          <Typography variant="caption" noWrap>
            © 2021 Platfarm Inc. All rights reserved.
          </Typography>
        </div>
        <div
          id="privacy policy"
          className={classes.footerBottomMenu}
          onClick={onClickReport}
        >
          <Button
            id="PRIVACY_POLICY"
            disabled
            style={{ textTransform: "none" }}
          >
            <Typography variant="caption" noWrap>
              Privacy Policy
            </Typography>
          </Button>
        </div>
      </div>

      <div className={classes.footerBottomIconWrapper}>
        <div className={classes.footerBottomIconBox}>
          <Link underline="none" href="https://www.facebook.com/withMojitok/">
            <img
              id="LINK_FACEBOOK_ICON"
              className={classes.footerBottomIcon}
              src={faceBookIcon}
              alt="facebook icon"
            ></img>
          </Link>
          <Link
            underline="none"
            href="https://www.instagram.com/mojitok_official/ "
          >
            <img
              id="LINK_INSTAGRAM_ICON"
              className={classes.footerBottomIcon}
              src={instagramIcon}
              alt="instagram icon"
            ></img>
          </Link>
          <Link
            underline="none"
            href="https://www.linkedin.com/company/mojitok-inc/"
          >
            <img
              id="LINK_LINKEDIN_ICON"
              className={classes.footerBottomIcon}
              src={linkedInIcon}
              alt="linkdin icon"
            ></img>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FooterBottom;
