import React from "react";
import "../styles/Articles.css";
import stickerAndUserImage from "../imgs/points_users.png";
import contentSourceImage from "../imgs/150000_small_size.png";
import { useHistory } from "react-router-dom";
import { Button, Link, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  UnlimitedStickerWrapper: {
    display: "flex",
    position: "relative",

    background:
      "radial-gradient(100% 576% at 100% 0%, #93c2ff 0%, #368dff 100%)",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  UnlimitedStickerImageWrapper: {
    [theme.breakpoints.down("lg")]: {
      margin: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      height: "100%",
    },
  },
  UnlimitedStickerImage: {
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "auto",
      width: "57.1042vw",
    },
  },
  UnlimitedStickerTextWrapper: {
    display: "flex",
    flexDirection: "column",
    // position: "absolute",
    // right: 0,
    // top: "50%",
    // transform: "translate(0,-50%)"
    // display: "flex",
    // flexDirection: "column",
  },
  UnlimitedStickerTextBox: {
    [theme.breakpoints.down("lg")]: {
      maxWidth: "700px",
      marginTop: "12.5vw",
      marginBottom: "12.5vw",
      marginLeft: "120px",
      marginRight: "120px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "auto",
      marginRight: "3.5vw",
    },
  },
  UnlimitedStickerTextHeader: {},
  UnlimitedStickerTextBody: { marginTop: "24px", maxWidth: "600px" },
  UnlimitedStickerButtonBox: {},
  UnlimitedStickerButtonLeftBox: {
    marginRight: "12px",
    marginTop: "40px",
    backgroundColor: "#ffffff",
    border: "1px solid #D3D7DD",
  },
  UnLimitedStickerButtonRightBox: {
    marginLeft: "12px",
    marginTop: "40px",
    backgroundColor: "#ffffff",
    border: "1px solid #D3D7DD",
    flexGrow: 1,
    maxWidth: "270px",
  },
  UnlimitedStickerButton: {
    padding: "1.6vh 22px",
  },
  UnlimitedStickerButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

function UnlimitedSticker() {
  let history = useHistory();
  const classes = useStyles();

  const onClickReport = () => {
    console.log("clicked");
    history.push(`/report`);
  };
  return (
    <div className={classes.UnlimitedStickerWrapper}>
      <div className={classes.UnlimitedStickerImageWrapper}>
        <img
          className={classes.UnlimitedStickerImage}
          src={stickerAndUserImage}
        ></img>
      </div>
      <div className={classes.UnlimitedStickerTextWrapper}>
        <div className={classes.UnlimitedStickerTextBox}>
          <Typography variant="h1">Content source</Typography>
          <div className={classes.UnlimitedStickerTextBody}>
            <Typography variant="body1">
              mojitok Stickerfarm has a community of talented creators uploading
              new stickers every day
            </Typography>
            {/* <Typography variant="body1">
              talented creators uploading new stickers
            </Typography> */}
            {/* <Typography variant="body1">every day</Typography> */}
          </div>
          <div className={classes.UnlimitedStickerButtonWrapper}>
            <Link href="https://stickerfarm.mojitok.com/" underline="none">
              <Button
                id="GO_TO_STICKERFARM"
                className={classes.UnlimitedStickerButtonLeftBox}
              >
                <Typography
                  className={classes.UnlimitedStickerButton}
                  variant="button"
                  noWrap
                >
                  GO TO STICKERFARM
                </Typography>
              </Button>
            </Link>

            <Button
              id="VIEW_REPORT"
              className={classes.UnLimitedStickerButtonRightBox}
              onClick={onClickReport}
            >
              <Typography
                className={classes.UnlimitedStickerButton}
                variant="button"
                noWrap
              >
                VIEW REPORT
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnlimitedSticker;
