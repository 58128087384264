import React from "react";
import "../styles/Store.css";
import storeRevenueImg from "../imgs/store_revenue.png";
import storeEngagementImg from "../imgs/store_engagement.png";
import { Button, Link, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  storeWrapper: {
    background: "#f5f8ff",
    display: "flex",
    flexDirection: "column",
  },
  storeTitleWrapper: { marginTop: "160px" },
  storeHeader: {
    fontFamily: "Gmarket Sans TTF BOLD",
    fontWeight: "700",
    fontStyle: "normal",
    fontSize: "64px",
    textAlign: "center",
    color: "#2164E4",
  },
  storeBody: {
    marginTop: "32px",
    textAlign: "center",
    marginRight: "32px",
    marginLeft: "32px",
  },
  storeContentWrapper: {
    marginTop: "82px",
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  storeEngagementWrapper: {
    [theme.breakpoints.down("lg")]: {
      margin: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "auto",
      marginRight: "20px",
    },
  },
  storeEngagementBox: {
    background: "#ffffff",
    borderRadius: "24px",
    display: "flex",
    flexDirection: "column",
  },
  storeRevenueBox: {
    background: "#ffffff",
    borderRadius: "24px",
    display: "flex",
    flexDirection: "column",
  },
  storeRevenueWrapper: {
    [theme.breakpoints.down("lg")]: {
      margin: "auto",
      marginTop: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
      marginLeft: "20px",
      marginRight: "auto",
    },
  },
  storeEngagementImg: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "40px",
    height: "400px",
  },
  storeEngagementSubtitle: { textAlign: "center", marginTop: "120px" },
  storeEngagementBody3: {
    margin: "auto",
    maxWidth: "500px",
    textAlign: "center",
    marginTop: "24px",
    marginBottom: "104px",
    marginLeft: "24px",
    marginRight: "24px",
  },
  storeRevenueImg: {
    height: "400px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "40px",
  },
  storeRevenueSubtitle: {
    textAlign: "center",
    marginTop: "120px",
  },
  storeRevenueBody3: {
    textAlign: "center",
    marginTop: "24px",
    marginBottom: "104px",
    marginLeft: "24px",
    marginRight: "24px",
  },
  storeTypographyBody3: {
    margin: "auto",
    maxWidth: "500px",
    fontFamily: "Gmarket Sans TTF",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "28.8px",
    color: "#233653",
  },
  storeButtonBox: {
    width: "260px",
    backgroundColor: "#3278FF",
  },
  storeButton: { color: "#ffffff", margin: "auto", padding: "1.6vh 4.16vw" },
  storeDescription: {
    marginTop: "122px",
    marginBottom: "128px",
    textAlign: "center",
  },
  storeDescriptionSky: {
    color: "#A1CAFF",
  },
  storeDescriptionBlue: {
    color: "#2164E4",
  },
  storeButtonLink: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "128px",
  },
  storeDescriptionText: {
    maxWidth: "1100px",
    margin: "auto",
  },
}));

function Store() {
  const classes = useStyles();
  return (
    <div id="store" className={classes.storeWrapper}>
      <div className={classes.storeTitleWrapper}>
        <Typography className={classes.storeHeader}>
          mojitok Sticker Store
        </Typography>
        <div className={classes.storeBody}>
          <Typography className={classes.storeDescriptionText} variant="body1">
            Everything you need to know about our solution. Browse through our
            technical documents, learn how to integrate, and get answers to our
            FAQ.
          </Typography>
        </div>
      </div>
      <div className={classes.storeContentWrapper}>
        <div className={classes.storeEngagementWrapper}>
          <div className={classes.storeEngagementBox}>
            <img
              className={classes.storeEngagementImg}
              src={storeEngagementImg}
            ></img>
            <div className={classes.storeEngagementSubtitle}>
              <Typography variant="subtitle2">Increase Engagement</Typography>
            </div>
            <div className={classes.storeEngagementBody3}>
              <Typography className={classes.storeTypographyBody3}>
                Increase user activity with your choice of stickers and sticker
                API experiences.
              </Typography>
              <div></div>
            </div>
          </div>
        </div>
        <div className={classes.storeRevenueWrapper}>
          <div className={classes.storeRevenueBox}>
            <img
              className={classes.storeRevenueImg}
              src={storeRevenueImg}
            ></img>
            <div className={classes.storeRevenueSubtitle}>
              <Typography variant="subtitle2">Generate Revenue</Typography>
            </div>
            <div className={classes.storeRevenueBody3}>
              <Typography className={classes.storeTypographyBody3}>
                Sell stickers to your users with your choice of stickers,
                sticker API experiences, and custom UIs
              </Typography>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.storeDescription}>
        <Typography className={classes.storeDescriptionSky} variant="subtitle1">
          “Yes, it’s that easy.
        </Typography>
        <Typography className={classes.storeDescriptionSky} variant="subtitle1">
          What are you waiting for?
        </Typography>
        <Typography
          className={classes.storeDescriptionBlue}
          variant="subtitle1"
        >
          Generate more app revenue today!”
        </Typography>
      </div>
      <Link
        className={classes.storeButtonLink}
        underline="none"
        href="https://www.notion.so/mojitok-Sticker-Store-SDK-Docs-27c5b29bb0d546549bd73e860044765d"
      >
        <Button id="SDK_DOC" className={classes.storeButtonBox}>
          <Typography variant="button" className={classes.storeButton}>
            SDK DOC
          </Typography>
        </Button>
      </Link>
    </div>
  );
}

export default Store;
