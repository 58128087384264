import React from "react";
import "../styles/Header.css";
import logoMojitok from "../imgs/logo_mojitok.png";
// import logoMojitokIcon from "../imgs/logo_mojitok_icon.png";
import logoMojitokIcon from "../imgs/new_logo.png";
import logo_mojitok_header from "../imgs/logo_mojitok_header.png";


import { Button, Link, makeStyles, Typography } from "@material-ui/core";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    position: "absolute",
    left: "0px",
    top: "0px",
    height: "100px",
    width: "100%",
    backgroundColor: "#329DFF",
  },
  headerWrapperFixed: {
    position: "sticky",
    left: "0px",
    top: "0px",
    width: "100%",
    height: "100px",
    zIndex: 9999,
    backgroundColor: "#329DFF",
  },
  headerBox: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
  mojitokLogo: {
    position: "relative",
    minWidth: "7vw",
    height: "100px",
    marginLeft: "16px",
    marginRight: "5vw",
  },
  mojitokLogoIcon: {
    position: "relative",
    width: "60px",
    height: "60px",
    marginTop: "auto",
    marginBottom: "auto",

    [theme.breakpoints.down("lg")]: {
      marginLeft: "7.354vw",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "12.5vw",
    },
  },
  headerMenu: {
    position: "relative",
    display: "flex",
    margin: "auto",
  },
  headerMenuButton: {
    fontFamily: "Gmarket Sans TTF",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#ffffff",
    marginRight: "3.333vw",
  },
  headerForCreatorButton: {
    fontFamily: "Gmarket Sans TTF BOLD",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#FFF0A1",
    marginRight: "3.333vw",
    marginLeft: "2.2vw",
    textUnderlinePosition: "under",
    textDecoration: "underline",
    [theme.breakpoints.down("xl")]: {
      marginLeft: "12px",
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "2.2vw",
    },
  },
  headerContactDiv: {
    marginRight: "12.5vw",
    display: "flex",
    [theme.breakpoints.down("xl")]: {
      display: "none",
    },
    [theme.breakpoints.up("xl")]: {
      display: "flex",
    },
  },
  headerContactBox: {
    margin: "auto",
  },
  headerMuiButton: {
    border: "2px solid #ffffff",
    padding: "17px 20px",
    borderRadius: "5px",
  },
  headerMuiButtonText: {
    color: "#ffffff",
  },
  hashLink: {
    textDecoration: "none",
  },
  headerIconWrapper: {
    display: "flex",
  },
}));

function Header() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.headerWrapperFixed}>
        <div className={classes.headerBox}>
          <HashLink className={classes.hashLink} to="#home">
            
            <div className={classes.headerIconWrapper}>
              <img
                id="HEADER_LOGO_MOJITOK"
                className={classes.mojitokLogoIcon}
                src={logoMojitokIcon}
              ></img>
              <img className={classes.mojitokLogo} src={logoMojitok}></img>
            </div>
          </HashLink>

          <div className={classes.headerMenu}>
            <HashLink className={classes.hashLink} to="#demo">
              <Typography className={classes.headerMenuButton}>Demo</Typography>
            </HashLink>
            <HashLink className={classes.hashLink} to="#happier">
              <Typography id="FEATURES" className={classes.headerMenuButton}>
                Features
              </Typography>
            </HashLink>

            <HashLink className={classes.hashLink} to="#store">
              <Typography id="PRODUCT" className={classes.headerMenuButton}>
                Product
              </Typography>
            </HashLink>
            <HashLink className={classes.hashLink} to="#useCase">
              <Typography
                id="USECASES"
                className={classes.headerMenuButton}
                noWrap
              >
                Use cases
              </Typography>
            </HashLink>
            <Link
              className={classes.hashLink}
              href="https://medium.com/platfarm"
            >
              <Typography id="BLOG" className={classes.headerMenuButton}>
                Blog
              </Typography>
            </Link>
            <Link
              className={classes.hashLink}
              href="https://stickerfarm.mojitok.com/"
            >
              <Typography
                id="FOR_CREATORS"
                className={classes.headerForCreatorButton}
                noWrap
              >
                For Creators
              </Typography>
            </Link>
          </div>
          <div className={classes.headerContactDiv}>
            <div className={classes.headerContactBox}>
              <HashLink className={classes.hashLink} to="#emailContact">
                <Button id="GET_IN_TOUCH" className={classes.headerMuiButton}>
                  <Typography
                    variant="button"
                    className={classes.headerMuiButtonText}
                    noWrap
                  >
                    GET IN TOUCH
                  </Typography>
                </Button>
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
