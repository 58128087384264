import React, {Component} from 'react';
import { Button } from '@material-ui/core';

import en from '../txt/privacy_en';
import ko from '../txt/privacy_ko';

const btnStyle = {
  width: '80px',
  height: '32px',
  borderRadius: '4px',
  border: 'solid 1px #d9d9d9',
  margin: 2.5,
  fontSize: 14,
  fontWeight: 400,
  textTransform: 'none',
  fontFamily: 'SpoqaHanSans-Regular',
};

const txtDftStyle={
  fontFamily: 'SpoqaHanSans-Regular',
  fontSize : 16,
  color: '#7a7a7a',
  margin:'15px 0px'
}

const subtitleStyle = {
  fontFamily: 'SpoqaHanSans-Bold',
  fontSize : 16,
  color: '#7a7a7a',
  margin:'15px 0px'
}
const titleStyle = {
  fontFamily: 'SpoqaHanSans-Bold',
  fontSize : 20,
  color: '#7a7a7a',
  margin:'15px 0px'
}

const txtArray = [
  "title", "description", "subtitle_1", "content_1", "subtitle_2", "content_2", "subtitle_3", "content_3",
  "subtitle_4", "content_4", "subtitle_5", "content_5", "subtitle_6", "content_6", "subtitle_7", "content_7",
  "subtitle_8", "content_8", "subtitle_9", "content_9", "subtitle_10", "content_10", "subtitle_11", "content_11",
]
class PrivacyPolicy extends Component {

  constructor(props){
    super(props);
    this.state = {
      privacy : en
    };
  }

  render() {
    return (
      <div style={{maxWidth:1200, margin:'10px auto', width:'80%'}}>
        <Button style={btnStyle} onClick={()=> this.setState({privacy: ko})}>Korean</Button>
        <Button style={btnStyle} onClick={()=> this.setState({privacy: en})}>English</Button>
        <div style={{margin:'30px auto'}}>

          {
              txtArray.map(key =>
                <div style={key === 'title' ? titleStyle : key.includes('title') ? subtitleStyle : txtDftStyle}>
                {
                  this.state.privacy[key].split('\n').map( line => <p style={{marginBottom:10, minHeight:3}}>{line}</p>)
                }
                </div>
              )

          }

          <div style={{marginTop:40}}>
          {
            this.state.privacy["version"].split('\n').map( line => <p style={{fontFamily: 'SpoqaHanSans-Regular',
            fontSize : 16, marginBottom:1,
            color: '#7a7a7a'}}>{line}</p>)
          }
          </div>

        </div>
      </div>

    )
  }

} //end PrivacyPolicy class

export default PrivacyPolicy;
