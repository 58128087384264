import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#3278FF",
      main: "#3278FF",
    },
    secondary: {
      light: "#979797",
      main: "#979797",
    },
    error: {
      light: "#F46D42",
      main: "#F46D42",
    },
    grey2: {
      main: "#919BA9",
    },
    mojitokNavy: { main: "#233653" },
    mojitokBlue100: {},
    mojitokBlue200: { main: "#3278FF" },
  },
  typography: {
    h1: {
      fontFamily: "Gmarket Sans TTF BOLD",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "48px",
      lineHeight: "72px",
      color: "#233653",
    },
    h2: {
      fontFamily: "Gmarket Sans TTF BOLD",
      fontWeight: 700,
      fontSize: "70px",
      lineHeight: "108px",
      color: "#233653",
    },
    h3: {},
    h4: {},
    body1: {
      fontFamily: "Gmarket Sans TTF",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "43.2px",
      color: "#233653",
    },
    body2: {
      fontFamily: "Sansita One",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "32px",
      lineHeight: "48px",
    },
    body3: {},
    button: {
      fontFamily: "Gmarket Sans TTF BOLD",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "32.4px",
      letter: "4%",
      color: "#3278FF",
    },
    subtitle1: {
      fontFamily: "Sansita One",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "48px",
      lineHeight: "57.6px",
    },
    subtitle2: {
      fontFamily: "Gmarket Sans TTF BOLD",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "48px",
      color: "#233653",
    },
    subtitle3: {
      fontFamily: "Gmarket Sans TTF",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "25.2px",
    },
    caption: {
      fontFamily: "Gmarket Sans TTF",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "25.2px",
      color: "#919BA9",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1511,
    },
  },

  overrides: {
    MuiFormControl: {
      root: {
        display: "flex",
      },
    },
    MuiInput: {
      // input 의 underline 효과 제외
      root: {
        fontFamily: "Roboto",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "400",
        // color: "#919BA9",
      },
    },
    // MuiButton: {
    //   root: {
    //     padding: "30px 88px",
    //   },
    //   contained: {
    //     boxShadow: "none",
    //   },
    // },
    // MuiInput: {
    //   // input 의 underline 효과 제외
    //   underline: {
    //     "&:before": {
    //       display: "none",
    //     },
    //     "&:after": {
    //       display: "none",
    //     },
    //   },
    // },
    // MuiChip: {
    //   label: {
    //     color: "#FFFFFF",
    //     fontStyle: "normal",
    //     fontWeight: "normal",
    //     fontSize: "12px",
    //     lineHeight: "18px",
    //   },
    // },
    // MuiButton: {
    //   root: {
    //     padding: "3px 8px",
    //   },
    //   contained: {
    //     boxShadow: "none",
    //   },
    // },
  },
});

export default theme;
