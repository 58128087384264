import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import report01 from "../imgs/report_01.svg";
import report02 from "../imgs/report_02.svg";
import report03 from "../imgs/report_03.svg";
import report04 from "../imgs/report_04.png";
import report05 from "../imgs/report_05.png";
import report06 from "../imgs/report_06.png";
import report07 from "../imgs/report_07.svg";
import report08 from "../imgs/report_08.png";
import report09 from "../imgs/report_09.png";
import report10 from "../imgs/report_10.png";
import report11 from "../imgs/report_11.svg";
import report12 from "../imgs/report_12.png";
import report13 from "../imgs/report_13.png";
import report14 from "../imgs/report_14.png";
import report15 from "../imgs/report_15.png";
import report16 from "../imgs/report_16.png";
import report17 from "../imgs/report_17.png";
import report18 from "../imgs/report_18.png";
import report19 from "../imgs/report_19.png";
import report20 from "../imgs/report_20.png";
import report21 from "../imgs/report_21.png";
import report22 from "../imgs/report_22.png";
import report23 from "../imgs/report_23.png";

const useStyles = makeStyles((theme) => ({
  imgWrapper: {
    width: "100vw",
  },
  root: {
    background: "#1A1A1A",
  },
}));

function ReportPage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img className={classes.imgWrapper} src={report01}></img>
      <img className={classes.imgWrapper} src={report02}></img>
      <img className={classes.imgWrapper} src={report03}></img>
      <img className={classes.imgWrapper} src={report04}></img>
      <img className={classes.imgWrapper} src={report05}></img>
      <img className={classes.imgWrapper} src={report06}></img>
      <img className={classes.imgWrapper} src={report07}></img>
      <img className={classes.imgWrapper} src={report08}></img>
      <img className={classes.imgWrapper} src={report09}></img>
      <img className={classes.imgWrapper} src={report10}></img>
      <img className={classes.imgWrapper} src={report11}></img>
      <img className={classes.imgWrapper} src={report12}></img>
      <img className={classes.imgWrapper} src={report13}></img>
      <img className={classes.imgWrapper} src={report14}></img>
      <img className={classes.imgWrapper} src={report15}></img>
      <img className={classes.imgWrapper} src={report16}></img>
      <img className={classes.imgWrapper} src={report17}></img>
      <img className={classes.imgWrapper} src={report18}></img>
      <img className={classes.imgWrapper} src={report19}></img>
      <img className={classes.imgWrapper} src={report20}></img>
      <img className={classes.imgWrapper} src={report21}></img>
      <img className={classes.imgWrapper} src={report22}></img>
      <img className={classes.imgWrapper} src={report23}></img>
    </div>
  );
}
export default ReportPage;
