import React from "react";
import successImage from "../imgs/contact_success_image.png";
import closeImage from "../imgs/close.png";
import { Button, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  ContactSuccessPageWrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "18vh",
    backgroundColor: "#FFFFFF",
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
  },
  ContactSuccessPageImage: {
    marginTop: "70px",
    marginLeft: "auto",
    marginRight: "auto",
    height: "254px",
    width: "324px",
  },
  ContactSuccessPageTitleWrapper: {
    marginTop: "49px",
    marginBottom: "24px",
    marginRight: "96px",
    marginLeft: "96px",
  },
  ContactSuccessPageTitle: {
    fontFamily: "Gmarket Sans TTF BOLD",
    fontWeight: 700,
    fontSize: "64px",
    lineHeight: "96px",
  },
  ContactSuccessPageBody: { marginBottom: "42px" },
  ContactSuccessPageBodyWrapper: { margin: "auto" },
  ContactSuccessPageButton: {
    marginTop: "116px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#3278FF",
  },
  ContactSuccessPageClose: {},
}));

function ContactSuccessPage() {
  const classes = useStyles();
  return (
    <div id="contactSuccess" className={classes.ContactSuccessPageWrapper}>
      <img className={classes.ContactSuccessPageImage} src={successImage}></img>
      <div className={classes.ContactSuccessPageTitleWrapper}>
        <Typography className={classes.ContactSuccessPageTitle}>
          Thanks for reaching out!
        </Typography>
      </div>
      <div className={classes.ContactSuccessPageBodyWrapper}>
        <Typography className={classes.ContactSuccessPageBody}>
          You’ll be hearing back from our team as soon as possible
        </Typography>
      </div>
    </div>
  );
}

export default ContactSuccessPage;
