import React, { useEffect, useState } from "react";
import Articles from "./Articles";
import EmailContact from "./EmailContact";
import Experiences from "./Experiences";
import Footer from "./Footer";
import Header from "./Header";
import HightPerformance from "./HighPerformance";
import Home from "./Home";
import Integration from "./Integration";
import MojitokSnackBar from "./MojitokSnackBar";
import Partnership from "./Partnership";
import Sellers from "./Sellers";
import Stickers from "./Stickers";
import Store from "./Store";
import Support from "./Support";
import UnlimitedSticker from "./UnlimitedSticker";
import UseageConter from "./UseageConter";
import UseCase from "./UseCase";

function setMetaData() {
  const meta = document.createElement("meta");
  meta.name = "viewport";
  meta.content = "width=device-width,  viewport-fit=cover";
  document.getElementsByTagName("head")[0].appendChild(meta);
}

function makeChatWidget() {
  var s1 = document.createElement("script");
  var s0 = document.getElementsByTagName("script")[0];

  s1.async = true;
  s1.src = "https://embed.tawk.to/602e01079c4f165d47c47628/1eupr81qk";
  s1.setAttribute("crossorigin", "*");
  s1.charset = "UTF-8";
  s0.parentNode.insertBefore(s1, s0);
}

function MojitokSDK() {
  useEffect(() => {
    setMetaData();
  });

  return (
    <div style={{ minWidth: "1000px", width: "100vw", colorAdjust: "exact" }}>
      <Header />
      <Home />
      <Partnership />
      <UseageConter />
      <Stickers />
      <HightPerformance />
      <UnlimitedSticker />
      <Experiences />
      <Integration />
      <Store />
      <UseCase />
      <Support />
      <EmailContact />
      <Sellers />
      <Articles />
      <Footer />
      <MojitokSnackBar />
      {makeChatWidget()}
    </div>
  );
}
export default MojitokSDK;
