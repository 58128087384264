import React, { useState } from "react";

import "./App.css";
import { Switch, Route, useLocation } from "react-router-dom";
import MojitokSDK from "./layouts/MojitokSDK";
import ReportPage from "./layouts/ReportPage";
import PrivacyPolicy from "./layouts/PrivacyPolicy";
function App() {
  return (
    <Switch>
      <Route exact path="/" render={() => <MojitokSDK />} />
      <Route path="/report" render={() => <ReportPage />} />
      <Route path="/privacy" render={() => <PrivacyPolicy />} />
    </Switch>
  );
}

export default App;
