import React from "react";
import "../styles/Articles.css";
import experienceImg from "../imgs/experience_img.png";
import { Button, Divider, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  experiencesWrapper: {
    display: "flex",

    background:
      "radial-gradient(100% 576% at 100% 0%, #d9e9ff 0%, #a2caff 100%)",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      height: "800px",
    },
  },
  experiencesTextWrapper: {
    // width: "40.62vw",
    display: "flex",
  },
  experiencesTextBox: {
    [theme.breakpoints.down("lg")]: {
      marginLeft: "120px",
      marginRight: "120px",
      marginBottom: "80px",
      marginTop: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "auto",
      marginBottom: "auto",
      marginLeft: "16.30vw",
    },
  },
  experiencesTextHeader: {},
  experiencesTextBody: { marginTop: "24px" },
  experiencesButtonBox: {
    marginTop: "40px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#ffffff",
  },
  experiencesButton: { margin: "auto", padding: "1.6vh 4.16vw" },
  experiencesImageWrapper: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      margin: "auto",
      width: "1000px",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  experiencesImage: { width: "100%", margin: "auto" },
}));

function Experiences() {
  const classes = useStyles();
  return (
    <div className={classes.experiencesWrapper}>
      <div className={classes.experiencesTextWrapper}>
        <div className={classes.experiencesTextBox}>
          <Typography variant="h1">Experiences</Typography>
          <div className={classes.experiencesTextBody}>
            <Typography variant="body1" noWrap>
              Increase revenue and engagement
            </Typography>
            <Typography variant="body1" noWrap>
              immediately using our proven
            </Typography>
            <Typography variant="body1" noWrap>
              customizable user experiences
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.experiencesImageWrapper}>
        <img className={classes.experiencesImage} src={experienceImg}></img>
      </div>
    </div>
  );
}

export default Experiences;
