import React from "react";
import "../styles/Articles.css";
import articleLogo01 from "../imgs/article_logo_01.png";
import articleLogo02 from "../imgs/article_logo_02.png";
import articleLogo03 from "../imgs/article_logo_03.png";
import articleLogo04 from "../imgs/article_logo_04.png";
import articleLogo05 from "../imgs/article_logo_05.png";

import articleImg01 from "../imgs/article_img_01.png";
import articleImg02 from "../imgs/article_img_02.png";
import articleImg03 from "../imgs/article_img_03.png";
import articleImg04 from "../imgs/article_img_04.png";
import articleImg05 from "../imgs/article_img_05.png";

import testClose from "../imgs/close.png";
import arrowLeft from "../imgs/arrow_left.svg";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { Button, IconButton, makeStyles, Typography } from "@material-ui/core";
import ArticleItem from "./ArticleItem";

const useStyles = makeStyles((theme) => ({
  imgWrapper: {
    maxWidth: "100%",
  },
  articleWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    background: "#F5F8FF",
  },
  articleTextWrapper: {
    marginTop: "108px",
    marginBottom: "68px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  articleListWrapper: {
    display: "flex",
    overflow: "auto",
    marginLeft: "90px",
    marginRight: "90px",
    marginBottom: "145px",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  articleItemBox: {},
  articleItemImg: {},
  articleItemTitle: {},
  articleItemDateWrapper: {},
  articleItemDateImg: {},
  articleItemDateTitle: {},
  articleItemReadMore: {},
  articleLeftArrow: {
    position: "absolute",
    left: 95,
    background: "#FFFFFF",
    top: "50%",
    transform: "translate(-50%)",
  },
  articleRightArrow: {
    position: "absolute",
    top: "50%",
    transform: "translate(-50%)",
    right: 50,
    background: "#FFFFFF",
  },
}));

const sideScroll = (element, speed, distance, step) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

function Articles() {
  const classes = useStyles();
  const contentWrapper = React.useRef(null);
  return (
    <>
      <div className={classes.articleWrapper}>
        <div className={classes.articleTextWrapper}>
          <Typography variant="subtitle2">As seen on</Typography>
        </div>
        <div className={classes.articleListWrapper} ref={contentWrapper}>
          <ArticleItem
            imgUrl={articleImg05}
            articleLogo={articleLogo05}
            linkUrl="https://us.aving.net/mojitok-introduced-global-emoticon-service-at-trieverything-2021/"
            articleTitle="Mojitok’ introduced global emoticon service at Vietnam ..."
            articleDate="- December 15, 2021"
          ></ArticleItem>
          <ArticleItem
            imgUrl={articleImg01}
            articleLogo={articleLogo01}
            linkUrl="https://news.samsung.com/global/how-to-get-the-best-out-of-samsung-keyboard"
            articleTitle="How to Get the Best Out of Samsung Keyboard"
            articleDate="- May 7, 2020"
          ></ArticleItem>
          <ArticleItem
            imgUrl={articleImg02}
            articleLogo={articleLogo02}
            linkUrl="https://www.businesswire.com/news/home/20201019005263/en/7-Software-Startups-Showcase-in-the-Korea-National-Pavilion-at-Slush "
            articleTitle="7 Software Startups Showcase in the Korea National Pavilion at Slush"
            articleDate="- October 19, 2020"
          ></ArticleItem>
          <ArticleItem
            imgUrl={articleImg03}
            articleLogo={articleLogo03}
            linkUrl="https://newswatchtv.com/2019/09/23/mojitok-newswatch-review/"
            articleTitle="Spice up Your Texts Messages with Mojitok Stickers | NewsWatch Review"
            articleDate="- September 16, 2019"
          ></ArticleItem>
          <ArticleItem
            imgUrl={articleImg04}
            articleLogo={articleLogo04}
            linkUrl="https://www.asiae.co.kr/article/2020042716055827818"
            articleTitle="AI sticker solution, mojitok, partnered with Google's tenor GIF engine"
            articleDate="- April 27, 2020"
          ></ArticleItem>
          <IconButton
            className={classes.articleLeftArrow}
            color="primary"
            onClick={() => {
              sideScroll(contentWrapper.current, 40, 200, -40);
            }}
          >
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
          <IconButton
            className={classes.articleRightArrow}
            color="primary"
            onClick={() => {
              sideScroll(contentWrapper.current, 40, 200, 40);
            }}
          >
            <ChevronRightIcon fontSize="large" />
          </IconButton>
        </div>
      </div>
    </>
  );
}

export default Articles;
