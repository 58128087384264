import React from "react";
import "../styles/Footer.css";
import footer_img from "../imgs/footer_logo.png";
import footer_policy from "../imgs/footer_policy.png";
import { Link, makeStyles, Typography } from "@material-ui/core";
import { HashLink } from "react-router-hash-link";
import FooterBottom from "./FooterBottom";

const useStyles = makeStyles((theme) => ({
  footerWrapper: { display: "flex", flexDirection: "column" },
  footerMenuWrapper: { height: "277px" },
  footerMenu: {
    display: "flex",
    marginTop: "84px",
    marginLeft: "12.5vw",
    marginRight: "18.80vw",
    justifyContent: "space-between",
  },
  footerMenuIcon: {
    height: "30px",
    width: "110px",
  },
  footerMenuButton: {
    fontFamily: "Gmarket Sans TTF",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#233653",
    WebkitTextStroke: "0.4px #101010",
  },
  footerMenuDropDown: {},
  footerMenuDropDownItem: {},
  footerBottomWrapper: {
    borderTopColor: "#D3D7DD",
    borderTop: "1px solid",
  },
  footerBottomImg: { width: "100%" },
  hashLink: {
    textDecoration: "none",
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.footerWrapper}>
        <div className={classes.footerMenuWrapper}>
          <div className={classes.footerMenu}>
            <div>
              <img className={classes.footerMenuIcon} src={footer_img}></img>
            </div>
            <div id="LINK_DEMO" className={classes.footerMenuDropDown}>
              <Link href="https://demo.mojitok.com/" underline="none">
                <Typography className={classes.footerMenuButton}>
                  Demo
                </Typography>
              </Link>
            </div>
            <div id="LINK_FEATURES" className={classes.footerMenuButton}>
              <HashLink className={classes.hashLink} to="#happier">
                <Typography className={classes.footerMenuButton}>
                  Features
                </Typography>
              </HashLink>
            </div>
            <div id="LINK_USE_CASES" className={classes.footerMenuButton}>
              <HashLink className={classes.hashLink} to="#useCase">
                <Typography className={classes.footerMenuButton} noWrap>
                  Use cases
                </Typography>
              </HashLink>
            </div>
            <div id="LINK_BLOG" className={classes.footerMenuButton}>
              <Link
                className={classes.hashLink}
                href="https://medium.com/platfarm"
                underline="none"
              >
                <Typography className={classes.footerMenuButton}>
                  Blog
                </Typography>
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.footerBottomWrapper}>
          <FooterBottom></FooterBottom>
        </div>
      </div>
    </>
  );
}

export default Footer;
