import React, { useState } from "react";
import backgroundImage from "../imgs/map.png";
import howItWorkImage from "../imgs/how_it_work_image.png";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  imgWrapper: {
    maxWidth: "100%",
  },
  usageWrapper: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  usageImageWrapper: {
    display: "flex",
    marginTop: "80px",

    [theme.breakpoints.down("lg")]: {
      maxWidth: "70%",

      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "750px",
    },
  },
  usageImage: {
    width: "90%",
    height: "594px",
    [theme.breakpoints.down("lg")]: {
      margin: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "120px",
      marginLeft: "60px",
      maxHeight: "800px",
    },
  },
  usageContentWrapper: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  usageTextWapper: {
    flexGrow: 1,
    marginTop: "80px",
    marginLeft: "40px",
    display: "flex",
  },
  usageTextBox: {
    margin: "auto",
  },
  usageTextHeader: {
    /* mojitok Navy */

    color: "#233653",
  },
  bodyDivWrapper: {
    marginTop: "32px",
    display: "flex",
  },
  usageTextBodyWrapper: {
    maxWidth: "525px",
    marginLeft: "8px",
  },
  usageTextBodyNumber: {
    fontSize: "64px",
    fontWeight: "300",
    fontFamily: "Gmarket Sans TTF Light",
    fontStyle: "normal",
    color: "#233653",
  },
  usageTextBody: {
    /* mojitok Navy */

    fontWeight: "400",
    fontFamily: "Gmarket Sans TTF",
    fontStyle: "normal",
    fontSize: "32px",
    lineHeight: "57.6px",
    color: "#233653",
  },
  usageCountryNumberWrapper: {
    display: "flex",
    marginTop: "98px",
    marginBottom: "98px",
  },
  usageCountryNumber: {
    /* mojitok Blue */
    color: "#3278FF",
  },
  usageCountryNumberText: {
    marginTop: "9px",
  },
  usageCountryAndUsers: {
    marginLeft: "105px",
  },
  usageBackgroundImage: {
    position: "absolute",
    width: "1420px",
    height: "910px",
    left: "0px",
    bottom: "0px",
  },
}));

function UseageConter() {
  const classes = useStyles();
  return (
    <div className={classes.usageWrapper}>
      <div className="useage-counter__text">"How does it work?"</div>
      <div className={classes.usageContentWrapper}>
        <div className={classes.usageImageWrapper}>
          <img className={classes.usageImage} src={howItWorkImage}></img>
        </div>
        <div className={classes.usageTextWapper}>
          <div className={classes.usageTextBox}>
            <div className={classes.bodyDivWrapper}>
              <Typography className={classes.usageTextBodyNumber}>
                1 &ensp;
              </Typography>
              <div className={classes.usageTextBodyWrapper}>
                <Typography className={classes.usageTextBody}>
                  Receive our SDK/API file and documents from mojitok
                </Typography>
              </div>
            </div>
            <div className={classes.bodyDivWrapper}>
              <Typography className={classes.usageTextBodyNumber}>
                2 &ensp;
              </Typography>
              <div className={classes.usageTextBodyWrapper}>
                <Typography className={classes.usageTextBody}>
                  Integrate and customise to match your app
                </Typography>
              </div>
            </div>

            <div className={classes.bodyDivWrapper}>
              <Typography
                variant="body1"
                className={classes.usageTextBodyNumber}
              >
                3 &ensp;
              </Typography>
              <div className={classes.usageTextBodyWrapper}>
                <Typography className={classes.usageTextBody}>
                  Launch the sticker functions through an app update
                </Typography>
              </div>
            </div>

            <div className={classes.usageCountryNumberWrapper}>
              <div>
                <Typography
                  className={classes.usageCountryNumber}
                  variant="subtitle1"
                >
                  300M+
                </Typography>
                <Typography
                  className={classes.usageCountryNumberText}
                  variant="body1"
                >
                  Active users globally
                </Typography>
              </div>
              <div className={classes.usageCountryAndUsers}>
                <Typography
                  className={classes.usageCountryNumber}
                  variant="subtitle1"
                >
                  41 billion
                </Typography>
                <Typography
                  className={classes.usageCountryNumberText}
                  variant="body1"
                >
                  monthly impressions
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UseageConter;
